// import 'controllers';
import '../sprinkles/top';
import '../sprinkles/header';
import '../sprinkles/scroll';
import '../sprinkles/floatButton';
import '../sprinkles/cookieConsent';

window.addEventListener('load', () => {
  import('controllers').catch(error => {
    console.error('Error loading the controllers module:', error);
  });
});
